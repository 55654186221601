import request from '@/utils/request'

export default {
  getSignPlace(id) {
    return request({
      url: `/youth/yt-onlive/getSignPlace/`+id,
      method: 'get'
    })
  },
  getSignByPlaceId(placeId,page,limit) {
    return request({
      url: `/youth/yt-onlive/getSignByPlaceId/`+placeId+'/'+page+'/'+limit,
      method: 'get'
    })
  },
  list(placeId) {
    return request({
      url: `/youth/yt-onlive/showBiSaiList/`+placeId,
      method: 'get'
    })
  },
  getLastSignChild(placeId) {
    return request({
      url: `/youth/yt-onlive/getLastSignChild/`+placeId,
      method: 'get'
    })
  }
}
